import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { use100vh } from "react-div-100vh";

// Previews
import { withPreview } from "gatsby-source-prismic";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100000;
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => props.opacity};
  transition: 1000ms background-color ease, 500ms opacity ease;

  & .logo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 492px;
    padding: 20px;

    overflow: hidden;

    @media (max-width: 1230px) {
      width: 350px;
    }

    @media (max-width: 1050px) {
      width: 300px;
    }
  }

  & .image-container {
    position: relative;
    margin: 110px 0 0 0;

    height: calc(${(props) => props.height}px - 110px);
    padding: 0 20px;

    @media (max-width: 600px) {
      height: calc(${(props) => props.height}px - 60px);
      margin: 60px 0 0 0;
    }
  }

  & .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    width: calc(100% - 200px);
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1500px) {
      width: calc(100% - 80px);
    }

    @media (max-width: 1200px) {
      width: 100%;
    }

    & .single-splash-image {
      position: absolute;
      opacity: ${(props) => props.imageOpacity};
      transition: 1000ms opacity ease;

      & img {
        max-height: calc(${(props) => props.height}px - 200px);

        object-fit: contain;
      }

      &:nth-of-type(4n + 1) {
        transition-delay: 1000ms;

        width: 43%;
        max-width: 540px;
        padding: 0 0 0 20px;
        align-self: flex-end;

        left: 8%;

        & img {
          object-position: left;
        }

        @media (max-width: 1300px) {
          left: 0;
        }

        @media (max-width: 600px) {
          align-self: flex-start;
          padding: 0;
          width: 66%;

          // max-width: 200px;
        }
      }

      &:nth-of-type(4n + 2) {
        transition-delay: 2000ms;

        width: 50%;

        left: 28%;

        & img {
          max-width: 580px;
          margin: 0 auto;
          object-position: center;
        }

        @media (max-width: 600px) {
          top: 20%;
          left: unset;
          right: -20px;
        }
      }

      &:nth-of-type(4n + 3) {
        transition-delay: 3000ms;

        width: 33%;
        max-width: 480px;

        top: 5%;
        right: 0;

        & img {
          object-position: center;
        }

        @media (max-width: 600px) {
          width: 66%;
          top: 40%;
          right: unset;
          left: 50%;
          transform: translateX(-50%);

          // max-width: 200px;
        }
      }

      &:nth-of-type(4n + 4) {
        transition-delay: 4000ms;

        width: 33%;
        max-width: 450px;

        bottom: 50px;
        left: 58%;

        & img {
          object-position: left;
        }

        @media (max-width: 600px) {
          width: 50%;
          // max-width: 140px;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
`;

const SplashPreview = ({ data }) => {
  const [backgroundColor, setBackgroundColor] = useState(`#A2AFA0`);
  const [imagesVisible, setImagesVisible] = useState(false);
  const height = use100vh();

  const images = data.prismicSplash.data.images.map((image, index) => (
    <div key={`single_splash_image_${index}`} className="single-splash-image">
      {image.image.fluid !== null && (
        <img
          srcSet={image.image.fluid.srcSet}
          src={image.image.fluid.src}
          alt={image.image.alt}
          loading={`lazy`}
        />
      )}
    </div>
  ));

  useEffect(() => {
    const backgroundTimer = setTimeout(() => {
      setBackgroundColor(`#FAFAF5`);
      setImagesVisible(true);
    }, 500);
    return () => clearTimeout(backgroundTimer);
  }, []);

  return (
    <SplashContainer
      backgroundColor={backgroundColor}
      imageOpacity={imagesVisible ? 1 : 0}
      opacity={1}
      height={height}
    >
      <div className="image-container">
        <div className="flex">{images}</div>
      </div>
    </SplashContainer>
  );
};

export default withPreview(SplashPreview);

export const query = graphql`
  {
    prismicSplash {
      _previewable
      data {
        images {
          image {
            alt
            fluid(srcSetBreakpoints: [200, 600, 800, 900, 1200]) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;
