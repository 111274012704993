import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";
import { ConditionalWrapper } from "../components/utils/conditional-wrapper";

const Page = styled.div`
  padding: 60px 0 0 0;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Row = styled.div`
  width: 100%;

  &.text-row {
    margin: 0 0 70px 0;

    @media (max-width: 767px) {
      margin: 0 0 25px 0;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
  }
`;

const TitleBar = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  width: 100%;
  margin: 0 0 130px 0;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;

    margin: 0 0 25px 0;
  }

  & .details {
    grid-column: 5 / 13;

    @media (max-width: 767px) {
      grid-column: span 6;
    }

    & p,
    & h1 {
      margin: 0;
    }

    & .date {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;

      text-transform: lowercase;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 11px;
      }
    }

    & .title h1 {
      font-size: 26px;
      line-height: 34px;
      letter-spacing: 0.03em;

      text-transform: lowercase;

      @media (max-width: 960px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

const Article = styled.div`
  padding: 140px 20px 0 20px;

  &:nth-of-type(even) {
    background-color: #fff;
  }

  @media (max-width: 767px) {
    padding: 45px 15px 0 15px;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  margin: 0 -20px;

  @media (max-width: 767px) {
    margin: 0 -15px;
  }

  & .positioned-image {
    &.center {
      &.small {
        grid-column: 5 / 9;
        // margin: 0 0 65px 0;
      }
      &.large {
        grid-column: 4 / 10;
        // margin: 0 0 65px 0;
      }
    }

    &.left.large {
      grid-column: 1 / 7;
      margin: 0 0 0 -20px;

      & .image-caption {
        padding: 0 0 0 20px;
      }

      @media (max-width: 767px) {
        grid-column: 1 / 6;
        margin: 0 0 65px -15px;
      }
    }

    &.left.small {
      grid-column: 1 / 4;
    }

    &.right.large {
      grid-column: 6 / 13;
      margin: 0 -20px 0 0;

      @media (max-width: 767px) {
        grid-column: 2 / 7;
        margin: 0 -15px 0 0;
      }
    }

    &.right.small {
      grid-column: 9 / 13;

      @media (max-width: 767px) {
        grid-column: 4 / 7;
      }
    }
  }

  & .multi-positioned-images {
    &.layout-split {
      grid-column: 1 / 13;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      @media (max-width: 767px) {
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 10px;
      }

      & .image-left {
        grid-column: span 6;

        width: calc(100% + 20px);
        margin-left: -20px;

        & .image-caption {
          padding-left: 20px;
        }

        @media (max-width: 767px) {
          grid-column: span 5;

          width: calc(100% + 15px);
          margin: 0 0 0 -15px;
        }
      }

      & .image-right {
        margin: 150% 0 0 0;
        grid-column: 9 / 13;

        @media (max-width: 767px) {
          margin: 65px 0;
          grid-column: span 3;
        }
      }

      @media (max-width: 767px) {
        grid-column: span 6;
      }
    }

    &.layout-center {
      grid-column: 4 / 12;

      @media (max-width: 767px) {
        grid-column: span 6;
        margin: 0 0 65px 0;
      }

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;

      & .image-left {
        grid-column: span 1;
      }

      & .image-right {
        grid-column: span 1;
        margin: 32% 0 0 0;
      }
    }
  }

  & .positioned-text {
    max-width: 570px;

    &.left {
      grid-column: 1 / 5;
    }

    &.center {
      grid-column: 5 / 10;

      @media (max-width: 767px) {
        grid-column: 1/5;
      }
    }

    &.right {
      grid-column: 7 / 12;

      @media (max-width: 767px) {
        grid-column: 2/7;
      }
    }
  }

  & .credits-text {
    grid-column: 5 / 12;
    max-width: 570px;

    @media (max-width: 767px) {
      grid-column: 1 / 12;
    }
  }

  & .image-container {
    grid-column: 1/4;

    & .image-caption {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;

      margin: 5px 0 0 0;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 12px;
      }
    }

    @media (max-width: 767px) {
      order: 12;
    }
  }

  & .text-container {
    grid-column: 5 / 12;
    max-width: 810px;

    @media (max-width: 767px) {
      grid-column: span 6;
      order: 1;

      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
    }

    & .interview-container {
      max-width: 500px;
      margin: 0 0 70px 0;

      &:nth-of-type(odd) {
        @media (max-width: 767px) {
          margin: 0 0 25px 0;

          grid-column: 1/6;
        }
      }

      &:nth-of-type(even) {
        margin: 0 0 70px auto;

        @media (max-width: 767px) {
          margin: 0 0 25px auto;

          grid-column: 2/7;
        }
      }

      & .name {
        margin: 0;
      }

      & .text {
        font-size: 19px;
        line-height: 29px;
        letter-spacing: 0.02em;

        & p {
          margin: 0;
        }

        & a {
          &::before {
            content: "(";
          }
          &::after {
            content: ")";
          }
        }

        @media (max-width: 960px) {
          font-size: 14px;
          line-height: 19px;
        }

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }
`;

const Conversations = ({ data }) => {
  const content = data.prismicConversation.data.body.map((content, index) => {
    if (content.slice_type === "interview_text") {
      const text = content.items.map((item, index) => (
        <div
          className="interview-container"
          key={`single_interview_text_item_${index}`}
        >
          <p className="name small-text">{item.name}</p>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: item.text.html,
            }}
          />
        </div>
      ));

      return (
        <Row key={content.id} className="text-row">
          <Grid>
            {content.primary.image.fluid !== null && (
              <div className="image-container">
                <ConditionalWrapper
                  condition={
                    content.primary.link !== null &&
                    content.primary.link.url !== ""
                  }
                  wrapper={(children) => (
                    <Link to={content.primary.link.url}>{children}</Link>
                  )}
                >
                  <img
                    srcSet={content.primary.image.fluid.srcSet}
                    src={content.primary.image.fluid.src}
                    alt={content.primary.image.alt}
                    loading="lazy"
                  />
                </ConditionalWrapper>
                <p className="image-caption small-text">
                  {content.primary.image.alt}
                </p>
              </div>
            )}

            <div className="text-container">{text}</div>
          </Grid>
        </Row>
      );
    }

    if (content.slice_type === "text") {
      return (
        <Row key={content.id} className="text-row">
          <Grid>
            <div
              className={`positioned-text ${content.primary.text_position.toLowerCase()}`}
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </Grid>
        </Row>
      );
    }

    if (content.slice_type === "single_image") {
      return (
        <Row key={content.id}>
          <Grid>
            <div
              className={`positioned-image ${content.primary.image_position.toLowerCase()} ${content.primary.image_size.toLowerCase()}`}
            >
              {content.primary.image.fluid !== null && (
                <ConditionalWrapper
                  condition={
                    content.primary.link !== null &&
                    content.primary.link.url !== ""
                  }
                  wrapper={(children) => (
                    <Link to={content.primary.link.url}>{children}</Link>
                  )}
                >
                  <img
                    srcSet={content.primary.image.fluid.srcSet}
                    src={content.primary.image.fluid.src}
                    alt={content.primary.image.alt}
                    loading="lazy"
                  />
                </ConditionalWrapper>
              )}
              <div
                className="image-caption small-text"
                dangerouslySetInnerHTML={{
                  __html: content.primary.image_caption.html,
                }}
              />
              {/* {content.primary.image.alt !== null && (
                <p className="image-caption small-text">
                  {content.primary.image.alt}
                </p>
              )} */}
            </div>
          </Grid>
        </Row>
      );
    }

    if (content.slice_type === "two_images") {
      return (
        <Row key={content.id}>
          <Grid>
            <div
              className={`multi-positioned-images layout-${content.primary.image_layout.toLowerCase()}`}
            >
              {content.primary.image_left.fluid !== null && (
                <div className="image-left">
                  <ConditionalWrapper
                    condition={
                      content.primary.image_left_link !== null &&
                      content.primary.image_left_link.url !== ""
                    }
                    wrapper={(children) => (
                      <Link to={content.primary.image_left_link.url}>
                        {children}
                      </Link>
                    )}
                  >
                    <img
                      srcSet={content.primary.image_left.fluid.srcSet}
                      src={content.primary.image_left.fluid.src}
                      alt={content.primary.image_left.alt}
                      loading="lazy"
                    />
                  </ConditionalWrapper>
                  {/* {content.primary.image_left.alt !== null && (
                    <p className="image-caption small-text">
                      {content.primary.image_left.alt}
                    </p>
                  )} */}
                  <div
                    className="image-caption small-text"
                    dangerouslySetInnerHTML={{
                      __html: content.primary.image_left_caption.html,
                    }}
                  />
                </div>
              )}

              {content.primary.image_right.fluid !== null && (
                <div className="image-right">
                  <ConditionalWrapper
                    condition={
                      content.primary.image_right_link !== null &&
                      content.primary.image_right_link.url !== ""
                    }
                    wrapper={(children) => (
                      <Link to={content.primary.image_right_link.url}>
                        {children}
                      </Link>
                    )}
                  >
                    <img
                      srcSet={content.primary.image_right.fluid.srcSet}
                      src={content.primary.image_right.fluid.src}
                      alt={content.primary.image_right.alt}
                      loading="lazy"
                    />
                  </ConditionalWrapper>
                  {/* {content.primary.image_right.alt !== null && ( */}
                  <div
                    className="image-caption small-text"
                    dangerouslySetInnerHTML={{
                      __html: content.primary.image_right_caption.html,
                    }}
                  />
                  {/* // )} */}
                </div>
              )}
            </div>
          </Grid>
        </Row>
      );
    }

    if (content.slice_type === "credits") {
      return (
        <Row key={content.id} className="text-row">
          <Grid>
            <div
              className="credits-text small-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </Grid>
        </Row>
      );
    }
  });

  return (
    <Page>
      <Flex>
        <ContentContainer>
          <Article>
            <TitleBar>
              <div className="details">
                <p className="date">
                  {data.prismicConversation.first_publication_date}
                </p>

                <div
                  className="title bl-arctic-regular"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicConversation.data.title.html,
                  }}
                />
              </div>
            </TitleBar>
            {content}
          </Article>
        </ContentContainer>
      </Flex>
    </Page>
  );
};

export default withPreview(Conversations);

export const query = graphql`
  query Conversation($uid: String!) {
    prismicConversation(uid: { eq: $uid }) {
      data {
        title {
          html
        }
        body {
          ... on PrismicConversationBodyCredits {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicConversationBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
              text_position
            }
          }
          ... on PrismicConversationBodySingleImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  src
                  srcSet
                }
              }
              image_caption {
                html
              }
              image_position
              image_size
              link {
                url
              }
            }
          }
          ... on PrismicConversationBodyTwoImages {
            id
            slice_type
            primary {
              image_left {
                fluid {
                  srcSet
                  src
                }
                alt
              }
              image_left_link {
                url
              }
              image_left_caption {
                html
              }
              image_right {
                alt
                fluid {
                  src
                  srcSet
                }
              }
              image_right_caption {
                html
              }
              image_right_link {
                url
              }
              image_layout
            }
          }
          ... on PrismicConversationBodyInterviewText {
            id
            slice_type
            items {
              name
              text {
                html
              }
            }
            primary {
              image {
                fluid {
                  src
                  srcSet
                }
                alt
              }
              link {
                url
              }
            }
          }
        }
      }
      first_publication_date(formatString: "MMMM, YYYY")
    }
  }
`;
