import React, { useState, useContext } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { RemoveScroll } from "react-remove-scroll";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { Popup } from "../components/context/popup";

// // Icons

import { Marker, ActiveMarker } from "../components/icons/markers";

//Utils
import { GridLayout } from "../components/homepage/grid-layout";

const Page = styled.div`
  padding: 160px 0 0 0;

  @media (max-width: 767px) {
    padding: 105px 0 0 0;
  }
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  position: relative;
  width: 100%;
  padding: 0 0 35px 0;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;

    padding: 0 0 15px 0;
  }

  & .filter-container {
    grid-column: 7/13;

    @media (max-width: 767px) {
      grid-column: span 6;
    }
  }

  & .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    & button,
    & a {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.03em;

      margin: 0 0 0 15px;

      @media (max-width: 767px) {
        margin: 0 15px 0 0;
      }

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 11px;
      }

      &.hidden {
        opacity: 0;
      }

      &.with-marker {
        margin: 0 15px 0 0;

        &:last-of-type {
          margin: 0;

          @media (max-width: 767px) {
            margin: 0 10px 0 0;
          }
        }

        & svg {
          margin: 0 7px -2px 0;

          @media (max-width: 767px) {
            margin: 0 0 -2px 0;
          }
        }

        @media (max-width: 767px) {
          margin: 0 10px 0 0;
        }

        // @media (max-width: 767px) {
        //   &:first-of-type {
        //     order: 3;
        //   }

        //   &:last-of-type {
        //     order: 4;
        //   }
        // }
      }

      // &.archive-button {
      //   @media (max-width: 767px) {
      //     order: 2;
      //     // margin: 0;
      //   }
      // }

      @media (max-width: 767px) {
        opacity: ${(props) => props.opacity};

        &.open-filter-button {
          margin: 0 auto 0 0;
        }
      }
    }
  }

  & .filter-buttons-container {
    position: fixed;
    top: 111px;
    left: 0;
    right: 0;

    padding: 46px 20px 20px 20px;
    z-index: 100;

    max-height: calc(100vh - 110px);
    overflow: scroll;

    @media (max-width: 1230px) {
      top: 97px;
    }

    @media (max-width: 1050px) {
      top: 89px;
    }

    @media (max-width: 960px) {
      top: 87px;
    }

    @media (max-width: 767px) {
      padding: 43px 15px 20px 18px;
    }

    @media (max-width: 600px) {
      top: 80px;
    }

    @media (max-width: 500px) {
      top: 60px;
    }

    background: rgba(250, 250, 245, 0.92);

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 10px;
    }

    & .top-line {
      margin: 0 0 30px 0;

      @media (max-width: 600px) {
        margin: 0 0 5px -3px;
      }
    }

    & .bottom-line {
      min-height: 200px;
      margin: 0 0 0 -6px;

      @media (max-width: 600px) {
        margin: 0 0 0 -3px;
      }
    }

    & .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;
      align-items: baseline;

      grid-column: 7/13;

      @media (max-width: 767px) {
        grid-column: 1/7;
        grid-column-gap: 10px;
      }
    }

    & button {
      text-align: left;

      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.03em;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 16px;
      }

      display: block;

      & span {
        opacity: 0;
      }

      &.active {
        & span {
          opacity: 1;
        }
      }

      &:hover {
        & span {
          opacity: 1;
        }
      }
    }

    & .filter-options,
    & .filters-right {
      grid-column: 3/7;
    }

    & .filter-headings,
    & .filters-left {
      grid-column: 1/3;
    }

    & .filters-right {
      display: flex;
      flex-direciton: row;
      flex-wrap: wrap;

      & .clear-filter-button {
        margin: 0 30px 0 0;
      }
    }

    & .filter-options {
      & button {
        text-transform: lowercase;
      }
    }

    & .inner-filters-container {
      grid-column: 3/7;

      & button {
        display: block;
      }
    }
  }
`;

const Shop = ({ data, location }) => {
  const [activePopup, setActivePopup] = useContext(Popup);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [activeColor, setActiveColor] = useState("All");
  const [activeCollection, setActiveCollection] = useState("All");
  const [activeMaterial, setActiveMaterial] = useState("All");
  const [activeMaker, setActiveMaker] = useState("All");
  const [activeCategory, setActiveCategory] = useState("All");

  const [openFilter, setOpenFilter] = useState(null);

  const allGridObjects = data.prismicShop.data.body
    .map((collection) =>
      collection.items.filter((item) => item.object.document !== null)
    )
    .flat()
    .filter((item) => item.object.document.data.shopify_product !== null);

  const allCollections = data.prismicShop.data.body
    .map((collection) => collection.primary.title.text.toLowerCase())
    .map((collection) => (
      <button
        onClick={() => setActiveCollection(collection)}
        key={`single_filter_${collection}`}
        className={activeCollection === collection ? `active filter` : `filter`}
      >
        <span>(</span>
        {collection}
        <span>)</span>
      </button>
    ));

  const allColors = Array.from(
    new Set(
      allGridObjects
        .map((object) =>
          object.object.document.data.colours
            .filter((color) => color.single_colour !== null)
            .map((colour) => colour.single_colour.toLowerCase())
        )
        .flat()
    )
  )
    .sort()
    .map((color) => (
      <button
        onClick={() => setActiveColor(color)}
        key={`single_filter_${color}`}
        className={activeColor === color ? `active filter` : `filter`}
      >
        <span>(</span>
        {color}
        <span>)</span>
      </button>
    ));

  const allMaterials = Array.from(
    new Set(
      allGridObjects
        .map((object) =>
          object.object.document.data.filter_materials
            .filter((material) => material.single_material !== null)
            .map((material) => material.single_material.toLowerCase())
        )
        .sort()
        .flat()
    )
  ).map((material) => (
    <button
      onClick={() => setActiveMaterial(material)}
      key={`single_filter_${material}`}
      className={activeMaterial === material ? `active filter` : `filter`}
    >
      <span>(</span>
      {material}
      <span>)</span>
    </button>
  ));

  const allCategories = Array.from(
    new Set(
      allGridObjects
        .map((object) =>
          object.object.document.data.categories
            .filter((category) => category.single_category !== null)
            .map((category) => category.single_category.toLowerCase())
        )
        .sort()
        .flat()
    )
  )
    .sort()
    .map((category) => (
      <button
        onClick={() => setActiveCategory(category)}
        key={`single_filter_${category}`}
        className={activeCategory === category ? `active filter` : `filter`}
      >
        <span>(</span>
        {category}
        <span>)</span>
      </button>
    ));

  const allMakers = Array.from(
    new Set(
      allGridObjects
        .filter((object) => object.object.document.data.maker.document !== null)
        .map((object) =>
          object.object.document.data.maker.document.data.name.text.toLowerCase()
        )
        .flat()
    )
  )
    .sort()
    .map((maker) => (
      <button
        onClick={() => setActiveMaker(maker)}
        key={`single_filter_${maker}`}
        className={activeMaker === maker ? `active filter` : `filter`}
      >
        <span>(</span>
        {maker}
        <span>)</span>
      </button>
    ));

  return (
    <>
      <Helmet
        title={`Shop | Metamorphoses`}
        meta={[
          {
            name: "title",
            content: `Shop | Metamorphoses`,
          },
          {
            property: "og:title",
            content: `Shop | Metamorphoses`,
          },
          {
            property: "twitter:title",
            content: `Shop | Metamorphoses`,
          },
          {
            property: "og:url",
            content: `https://metamorphosesobjects.com${data.prismicShop.url}/`,
          },
          {
            property: "twitter:url",
            content: `https://metamorphosesobjects.com${data.prismicShop.url}/`,
          },
        ]}
      ></Helmet>

      <Page>
        <Filters opacity={isFiltersOpen ? 0 : 1}>
          <div className="filter-container">
            <div className="container">
              <Link to={`/`} className="with-marker">
                gallery <Marker />
              </Link>

              <Link to={`/shop/`} className="with-marker">
                shop <ActiveMarker />
              </Link>

              <button
                onClick={() => setActivePopup("archive")}
                className={
                  isFiltersOpen
                    ? `hidden archive-button`
                    : `visible archive-button`
                }
              >
                archive
              </button>

              <button
                onClick={() => setIsFiltersOpen(true)}
                className={
                  isFiltersOpen
                    ? `hidden open-filter-button`
                    : `visible open-filter-button`
                }
              >
                filter +
              </button>
            </div>

            {isFiltersOpen && (
              <>
                <RemoveScroll>
                  <div className="filter-buttons-container">
                    <div className="grid top-line">
                      <button
                        onClick={() => setIsFiltersOpen(false)}
                        className="filters-left"
                      >
                        filter +
                      </button>

                      <div className="filters-right">
                        <button
                          onClick={() => {
                            setOpenFilter(null);
                            setActiveColor("All");
                            setActiveMaterial("All");
                            setActiveMaker("All");
                            setActiveCategory("All");
                            setActiveCollection("All");
                          }}
                          className="clear-filter-button"
                        >
                          (clear filter)
                        </button>

                        <button
                          onClick={() => setIsFiltersOpen(false)}
                          className="close-button"
                        >
                          (close)
                        </button>
                      </div>
                    </div>

                    <div className="grid bottom-line">
                      <div className="filter-headings">
                        <button
                          onClick={() => {
                            setActiveColor("All");
                            setActiveMaterial("All");
                            setActiveMaker("All");
                            setActiveCategory("All");
                            setActiveCollection("All");

                            if (openFilter === "colour") {
                              setOpenFilter(null);
                            } else {
                              setOpenFilter("colour");
                            }
                          }}
                          className={
                            openFilter === "colour" ? `active filter` : `filter`
                          }
                        >
                          <span>(</span>colour<span>)</span>
                        </button>
                        <button
                          onClick={() => {
                            setActiveColor("All");
                            setActiveMaterial("All");
                            setActiveMaker("All");
                            setActiveCategory("All");
                            setActiveCollection("All");

                            if (openFilter === "material") {
                              setOpenFilter(null);
                            } else {
                              setOpenFilter("material");
                            }
                          }}
                          className={
                            openFilter === "material"
                              ? `active filter`
                              : `filter`
                          }
                        >
                          <span>(</span>material<span>)</span>
                        </button>
                        <button
                          onClick={() => {
                            setActiveColor("All");
                            setActiveMaterial("All");
                            setActiveMaker("All");
                            setActiveCategory("All");
                            setActiveCollection("All");

                            if (openFilter === "category") {
                              setOpenFilter(null);
                            } else {
                              setOpenFilter("category");
                            }
                          }}
                          className={
                            openFilter === "category"
                              ? `active filter`
                              : `filter`
                          }
                        >
                          <span>(</span>category<span>)</span>
                        </button>
                        <button
                          onClick={() => {
                            setActiveColor("All");
                            setActiveMaterial("All");
                            setActiveMaker("All");
                            setActiveCategory("All");
                            setActiveCollection("All");

                            if (openFilter === "maker") {
                              setOpenFilter(null);
                            } else {
                              setOpenFilter("maker");
                            }
                          }}
                          className={
                            openFilter === "maker" ? `active filter` : `filter`
                          }
                        >
                          <span>(</span>maker<span>)</span>
                        </button>

                        <button
                          onClick={() => {
                            setActiveColor("All");
                            setActiveMaterial("All");
                            setActiveMaker("All");
                            setActiveCategory("All");

                            if (openFilter === "collection") {
                              setOpenFilter(null);
                            } else {
                              setOpenFilter("collection");
                            }
                          }}
                          className={
                            openFilter === "collection"
                              ? `active filter`
                              : `filter`
                          }
                        >
                          <span>(</span>collection<span>)</span>
                        </button>
                      </div>

                      <div className="filter-options">
                        {openFilter === "colour" && (
                          <div className="inner-filters-container">
                            {allColors}
                          </div>
                        )}

                        {openFilter === "material" && (
                          <div className="inner-filters-container">
                            {allMaterials}
                          </div>
                        )}

                        {openFilter === "category" && (
                          <div className="inner-filters-container">
                            {allCategories}
                          </div>
                        )}

                        {openFilter === "maker" && (
                          <div className="inner-filters-container">
                            {allMakers}
                          </div>
                        )}

                        {openFilter === "collection" && (
                          <div className="inner-filters-container">
                            {allCollections}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </RemoveScroll>
              </>
            )}
          </div>
        </Filters>

        <GridLayout
          collections={data.prismicShop.data.body}
          activeCollection={activeCollection}
          activeColor={activeColor}
          activeMaterial={activeMaterial}
          activeMaker={activeMaker}
          activeCategory={activeCategory}
        />
      </Page>
    </>
  );
};

export default withPreview(Shop);

export const query = graphql`
  {
    prismicShop {
      url
      data {
        body {
          ... on PrismicShopBodyCollection {
            id
            primary {
              title {
                text
              }
              thumbnail {
                fluid {
                  srcSet
                  src
                }
              }
              image_2 {
                fluid {
                  srcSet
                  src
                }
              }
              image_3 {
                fluid {
                  srcSet
                  src
                }
              }
            }
            items {
              object {
                document {
                  ... on PrismicObject {
                    id
                    url
                    data {
                      shopify_product
                      maker {
                        document {
                          ... on PrismicMaker {
                            id
                            data {
                              name {
                                text
                              }
                            }
                          }
                        }
                      }
                      category
                      categories {
                        single_category
                      }
                      filter_materials {
                        single_material
                      }
                      colours {
                        single_colour
                      }
                      year
                      grid_thumbnail {
                        alt
                        fluid(srcSetBreakpoints: [200, 600, 800, 900, 1200]) {
                          src
                          srcSet
                        }
                        dimensions {
                          width
                          height
                        }
                      }
                      grid_hover_thumbnail {
                        alt
                        fluid(srcSetBreakpoints: [200, 600, 800, 900, 1200]) {
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
