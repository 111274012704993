import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: calc(100% - 17px);

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .collaborations-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
    max-height: 100%;
  }

  & .image-container.landscape {
    padding: 120px;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  & .image-container.portrait {
    padding: 80px;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: ${(props) => props.arrowWidth};
  }

  & .collaboration-slide {
    max-width: 940px;
    margin: 0 auto;

    max-height: calc(100% - 100px);
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  font-size: 14px;
  letter-spacing: 0.03em;
  text-transform: lowercase;

  & p {
    margin: 0;
  }

  & .flex-1 {
    flex: 1;
  }

  & flex-2 {
    flex: 2;
  }

  & .project-text {
    & button {
      display: block;
      margin: 0 0 0 auto;
    }
  }

  @media (max-width: 767px) {
    & .title,
    & .project-text {
      display: none;
    }
  }
`;

const Indicators = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & li {
    margin: 0 15px;
    cursor: pointer;

    & span {
      opacity: 0;
    }

    &.active {
      & span {
        opacity: 1;
      }
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }

    @media (max-width: 767px) {
      text-align: left;

      font-size: 11px;
      line-height: 20px;

      margin: 0 20px 0 0;
    }
  }

  @media (max-width: 600px) {
    padding: 0 15px;
  }
`;

export const CollaborationsGallery = ({
  content,
  maker,
  title,
  textSlide,
  arrowWidth,
  currentSlide,
  setCurrentSlide,
  setArrowWidth,
}) => {
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(content.length);
    setAllGalleryContent(content);
  }, []);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  let imageCounter = [];
  for (let i = 0; i < totalSlides; i++) {
    imageCounter.push(
      <li
        key={i}
        onClick={() => setCurrentSlide(i)}
        className={currentSlide === i ? `active` : ``}
      >
        <span>(</span>
        {i + 1}
        <span>)</span>
      </li>
    );
  }

  return (
    <GalleryContainer arrowWidth={arrowWidth}>
      <Carousel
        showThumbs={false}
        autoPlay={false}
        showStatus={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        className="collaborations-gallery"
        selectedItem={currentSlide}
        onChange={(index) => updateCurrentSlide(index)}
        stopOnHover={false}
        interval={7000}
        transitionTime={750}
        showIndicators={false}
      >
        {content}
      </Carousel>

      <Caption>
        <p className="title flex-1">
          {maker}, {title}
        </p>

        <Indicators className="flex-2">{imageCounter}</Indicators>

        <div className="project-text flex-1">
          <button
            onClick={() => {
              setCurrentSlide(textSlide);
              setArrowWidth(`50%`);
            }}
          >
            (project text)
          </button>
        </div>
      </Caption>
    </GalleryContainer>
  );
};
