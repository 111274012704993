import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { Helmet } from "react-helmet";
import { RemoveScroll } from "react-remove-scroll";

// Hooks
import { useMeasure } from "react-use";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Components
import { CollaborationsGallery } from "../components/images/collaborations-gallery";

// Utils
import { SingleImage } from "../components/images/single-image";

const Page = styled.div`
  height: 100%;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;

  height: 100%;
  padding: 85px 0 25px 0;

  @media (max-width: 767px) {
    padding: 60px 0 15px 0;
  }
`;

const CollaborationSlide = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  height: 100%;

  @media (max-width: 600px) {
    padding: 0 15px;
  }

  &.text-slide-container {
    padding: 20px 0;
    align-items: center;

    @media (max-width: 600px) {
      padding: 20px 15px;

      & .additional-text-slide {
        padding: 57px 15px 0 15px;
      }
    }

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  & .text-slide {
    max-width: 920px;
    margin: 0 auto;

    font-size: 19px;
    line-height: 29px;
    letter-spacing: 0.02em;

    & a {
      &::before {
        content: "(";
      }
      &::after {
        content: ")";
      }
    }

    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 19px;

      margin: 0;
    }

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 16px;
    }
  }

  & img {
    object-fit: contain;
  }

  &.image-slide {
    align-items: center;
    align-content: center;

    & .image-container {
      width: fit-content;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      margin: 0 auto;
    }

    & a {
      display: block;
      width: fit-content;
      height: 100%;

      margin: 0 auto;
    }
  }

  & .mobile-title {
    display: none;
    margin: 0 0 25px 0;

    & p {
      margin: 0;
      text-transform: lowercase;
    }

    & .project-title {
      font-size: 14px;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }
`;

const TextSlide = styled.div`
  &.additional-text-slide {
    height: ${(props) => props.height};
  }
`;

const CollaborationsText = ({ index, textSlide, data, content, setHeight }) => {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  useEffect(() => {
    setHeight(height);
  }, [height]);

  return (
    <div className="text-slide" ref={ref}>
      {index === textSlide && (
        <div className="mobile-title">
          <p className="maker-name">
            {data.prismicCollaboration.data.maker.document !== null
              ? `${data.prismicCollaboration.data.maker.document.data.name.text}`
              : ``}
          </p>
          <p className="project-title bl-arctic-regular">
            {data.prismicCollaboration.data.title.text}
          </p>
        </div>
      )}

      <div
        dangerouslySetInnerHTML={{
          __html: content.primary.text.html,
        }}
      />
    </div>
  );
};

const Collaboration = ({ data }) => {
  const [arrowWidth, setArrowWidth] = useState(`50%`);
  const [currentSlide, setCurrentSlide] = useState(0);

  const textSlide = data.prismicCollaboration.data.body.findIndex(
    (slide) => slide.slice_type === "text"
  );
  const allSlides = data.prismicCollaboration.data.body.map(
    (slide) => slide.slice_type
  );

  useEffect(() => {
    if (allSlides[currentSlide] === "text") {
      setArrowWidth(`50%`);
    }
  }, [currentSlide]);

  const [height, setHeight] = useState(0);

  const content = data.prismicCollaboration.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <CollaborationSlide
          key={`collaboration_slide_${index}_${content.id}`}
          className={`text-slide-container ${
            index === textSlide ? `first-text-slide` : `additional-text-slide`
          }`}
        >
          {index === textSlide ? (
            <CollaborationsText
              index={index}
              data={data}
              content={content}
              textSlide={textSlide}
              setHeight={setHeight}
            />
          ) : (
            <TextSlide
              className="text-slide additional-text-slide"
              height={`${height}px`}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: content.primary.text.html,
                }}
              />
            </TextSlide>
          )}
        </CollaborationSlide>
      );
    }

    if (content.slice_type === "image") {
      return (
        <CollaborationSlide
          key={`collaboration_slide_${index}_${content.id}`}
          className="image-slide"
        >
          <SingleImage
            content={content}
            setArrowWidth={setArrowWidth}
            currentSlide={currentSlide}
            index={index}
          />
        </CollaborationSlide>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicCollaboration.data.title.text} | Metamorphoses`}
        meta={[
          {
            name: "title",
            content: `${data.prismicCollaboration.data.title.text} | Metamorphoses`,
          },
          {
            property: "og:title",
            content: `${data.prismicCollaboration.data.title.text} | Metamorphoses`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicCollaboration.data.title.text} | Metamorphoses`,
          },
          {
            property: "og:url",
            content: `https://metamorphosesobjects.com${data.prismicCollaboration.url}`,
          },
          {
            property: "twitter:url",
            content: `https://metamorphosesobjects.com${data.prismicCollaboration.url}`,
          },
        ]}
      >
        {/* <link
          rel="canonical"
          href={`https://metamorphosesobjects.com${data.prismicCollaboration.url}/`}
        /> */}
      </Helmet>

      <RemoveScroll>
        <Div100vh>
          <Page>
            <Flex>
              <CollaborationsGallery
                content={content}
                maker={
                  data.prismicCollaboration.data.maker.document !== null
                    ? data.prismicCollaboration.data.maker.document.data.name
                        .text
                    : null
                }
                title={data.prismicCollaboration.data.title.text}
                year={data.prismicCollaboration.data.year}
                textSlide={textSlide}
                arrowWidth={arrowWidth}
                setArrowWidth={setArrowWidth}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            </Flex>
          </Page>
        </Div100vh>{" "}
      </RemoveScroll>
    </>
  );
};

export default withPreview(Collaboration);

export const query = graphql`
  query Collaboration($uid: String!) {
    prismicCollaboration(uid: { eq: $uid }) {
      url
      data {
        title {
          text
        }
        year
        maker {
          document {
            ... on PrismicMaker {
              id
              data {
                name {
                  text
                }
              }
            }
          }
        }
        body {
          ... on PrismicCollaborationBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicCollaborationBodyImage {
            id
            slice_type
            primary {
              link {
                url
              }
              image {
                alt
                fluid {
                  src
                  srcSet
                }
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
