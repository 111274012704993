import React, { useEffect } from "react";
import { Link } from "gatsby";

// Hooks
import { useMeasure } from "react-use";

// Utils
import { ImageOrientation } from "../utils/image-orientation";
import { ConditionalWrapper } from "../utils/conditional-wrapper";

export const SingleImage = ({
  content,
  setArrowWidth,
  currentSlide,
  index,
}) => {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  useEffect(() => {
    if (currentSlide === index) {
      if (content.primary.link !== null && content.primary.link.url !== "") {
        setArrowWidth(`calc(((100vw - ${width}px) / 2) - 40px)`);
      } else {
        setArrowWidth(`50%`);
      }
    }
  }, [currentSlide, content, index, width]);

  return (
    <>
      {content.primary.image.fluid !== null && (
        <div
          ref={ref}
          className={`image-container ${ImageOrientation(
            content.primary.image
          )}`}
        >
          <ConditionalWrapper
            condition={
              content.primary.link !== null && content.primary.link.url !== ""
            }
            wrapper={(children) => (
              <Link to={content.primary.link.url}>{children}</Link>
            )}
          >
            <img
              srcSet={content.primary.image.fluid.srcSet}
              src={content.primary.image.fluid.src}
              alt={content.primary.image.alt}
              loading="lazy"
            />
          </ConditionalWrapper>
        </div>
      )}
    </>
  );
};
