import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// // Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

//Utils
import kebabCase from "lodash.kebabcase";
import { FormatProductTitle } from "../utils/format-product-title";
import { ConditionalWrapper } from "../utils/conditional-wrapper";

const SingleCollectionContainer = styled.div`
  margin: 0 0 60px 0;

  & .collection-image {
    margin: 0 0 60px 0;

    & img {
      object-position: top;
      height: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
  }

  &.grid-view {
    & .object {
      grid-column: span 4;

      @media (max-width: 767px) {
        grid-column: span 3;
      }
    }
  }
`;

const Product = styled.div`
  margin: 0 0 60px 0;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.thumbnail {
      visibility: visible;
    }
    &.hover-thumbnail {
      visibility: hidden;
    }
  }

  &.has-hover-thumbnail:hover {
    & .hover-thumbnail {
      visibility: visible;
    }
    & .thumbnail {
      visibility: hidden;
    }
  }

  & .container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 767px) {
      grid-template-columns: 1fr auto;
    }

    margin: 10px 0 0 0;

    & p,
    & h2 {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: lowercase;

      margin: 0;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 12px;
      }
    }

    & p.year {
      font-size: 12px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &.gallery-view {
    & .container {
      opacity: 0;
    }

    &:hover {
      & .container {
        opacity: 1;
      }
    }
  }

  &.grid-view {
    & .container {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    &.gallery-view,
    &.grid-view {
      & .container {
        opacity: 1;
      }
    }
  }
`;

export const GridLayout = ({
  collections,
  activeColor,
  activeMaterial,
  activeMaker,
  activeCategory,
  activeCollection,
}) => {
  const allCollections = collections
    .filter((collection) => {
      if (activeCollection === "All") {
        return collection;
      } else {
        return collection.primary.title.text.toLowerCase() === activeCollection;
      }
    })
    .map((collection, outerIndex) => {
      const collectionObjects = collection.items
        .filter((object) => object.object.document !== null)
        .filter(
          (object) => object.object.document.data.shopify_product !== null
        )
        .filter((object) => {
          if (activeColor === "All") {
            return object;
          } else {
            return object.object.document.data.colours
              .filter((colour) => colour.single_colour !== null)
              .map((colour) => colour.single_colour.toLowerCase())
              .includes(activeColor);
          }
        })
        .filter((object) => {
          if (activeMaterial === "All") {
            return object;
          } else {
            return object.object.document.data.filter_materials
              .filter((material) => material.single_material !== null)
              .map((material) => material.single_material.toLowerCase())
              .includes(activeMaterial);
          }
        })
        .filter((object) => {
          if (activeMaker === "All") {
            return object;
          } else {
            if (object.object.document.data.maker.document !== null) {
              return (
                object.object.document.data.maker.document.data.name.text.toLowerCase() ===
                activeMaker
              );
            }
          }
        })
        .filter((object) => {
          if (activeCategory === "All") {
            return object;
          } else {
            return object.object.document.data.categories
              .filter((category) => category.single_category !== null)
              .map((category) => category.single_category.toLowerCase())
              .includes(activeCategory);
          }
        })
        .map((object, index) => (
          <Product
            key={`single_product_${index}_${object.object.id}`}
            className={`object grid-view ${
              object.object.document.data.grid_hover_thumbnail.fluid !== null
                ? `has-hover-thumbnail`
                : `no-hover-thumbnail`
            }`}
          >
            <ConditionalWrapper
              condition={object.object.document.data.shopify_product !== null}
              wrapper={(children) => (
                <Link to={`${object.object.document.url}`}>{children}</Link>
              )}
            >
              <AspectRatioImageContainer image={null} padding={116}>
                {object.object.document.data.grid_thumbnail.fluid !== null && (
                  <img
                    className="thumbnail"
                    srcSet={
                      object.object.document.data.grid_thumbnail.fluid.srcSet
                    }
                    src={object.object.document.data.grid_thumbnail.fluid.src}
                    alt={object.object.document.data.grid_thumbnail.alt}
                    loading="lazy"
                  />
                )}

                {object.object.document.data.grid_hover_thumbnail.fluid !==
                  null && (
                  <img
                    className="hover-thumbnail"
                    srcSet={
                      object.object.document.data.grid_hover_thumbnail.fluid
                        .srcSet
                    }
                    src={
                      object.object.document.data.grid_hover_thumbnail.fluid.src
                    }
                    alt={object.object.document.data.grid_hover_thumbnail.alt}
                    loading="lazy"
                  />
                )}
              </AspectRatioImageContainer>

              <div className="container">
                <div className="left">
                  {object.object.document.data.shopify_product !== null && (
                    <>
                      <p className="maker">
                        {
                          FormatProductTitle(
                            object.object.document.data.shopify_product.title
                          )[0]
                        }
                      </p>
                      <h2 className="title">
                        {
                          FormatProductTitle(
                            object.object.document.data.shopify_product.title
                          )[1]
                        }
                      </h2>
                    </>
                  )}
                </div>

                <div className="right">
                  <p className="year">{object.object.document.data.year}</p>
                </div>
              </div>
            </ConditionalWrapper>
          </Product>
        ));

      return (
        <SingleCollectionContainer
          id={`collection-${kebabCase(collection.primary.title.text)}`}
          key={`single_shop_collection_${outerIndex}`}
        >
          {collection.primary.thumbnail.fluid !== undefined &&
            collection.primary.thumbnail.fluid !== null &&
            collection.primary.image_2.fluid !== undefined &&
            collection.primary.image_2.fluid !== null &&
            collection.primary.image_3.fluid !== undefined &&
            collection.primary.image_3.fluid !== null && (
              <Grid className={`grid-view`}>
                <div className="object grid-view collection-image">
                  {collection.primary.thumbnail.fluid !== null && (
                    <AspectRatioImageContainer image={null} padding={116}>
                      <img
                        srcSet={collection.primary.thumbnail.fluid.srcSet}
                        src={collection.primary.thumbnail.fluid.src}
                        alt={collection.primary.thumbnail.alt}
                        loading="lazy"
                      />
                    </AspectRatioImageContainer>
                  )}
                </div>

                <div className="object grid-view collection-image">
                  {collection.primary.image_2.fluid !== null && (
                    <AspectRatioImageContainer image={null} padding={116}>
                      <img
                        srcSet={collection.primary.image_2.fluid.srcSet}
                        src={collection.primary.image_2.fluid.src}
                        alt={collection.primary.image_2.alt}
                        loading="lazy"
                      />
                    </AspectRatioImageContainer>
                  )}
                </div>

                <div className="object grid-view collection-image">
                  {collection.primary.image_3.fluid !== null && (
                    <AspectRatioImageContainer image={null} padding={116}>
                      <img
                        srcSet={collection.primary.image_3.fluid.srcSet}
                        src={collection.primary.image_3.fluid.src}
                        alt={collection.primary.image_3.alt}
                        loading="lazy"
                      />
                    </AspectRatioImageContainer>
                  )}
                </div>
              </Grid>
            )}

          <Grid className={`grid-view`}>{collectionObjects}</Grid>
        </SingleCollectionContainer>
      );
    });

  return allCollections;
};
