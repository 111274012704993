import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { withUnpublishedPreview } from "gatsby-source-prismic";

// Pages
import About from "./about";
import Bag from "./bag";
import Conversations from "./conversations";
import Index from "./index";
import PrivacyPolicy from "./privacy-policy";
import Shop from "./shop";
import Splash from "./splash";

// Templates
import Collaboration from "../templates/collaboration";
import Conversation from "../templates/conversation";
import Product from "../templates/object";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  position: relative;

  padding: 44px 50px 50px 50px;

  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};

  height: 100vh;

  transition: 1500ms all ease;
  text-align: center;

  & h1 {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 0.2px;
  }

  & p {
    margin: 5px 0 0 0;
  }

  @media (max-width: 740px) {
    padding: 20px;

    & h1 {
      font-size: 19px;
      line-height: 22px;
    }
  }
`;

const NotFoundPage = () => (
  <Page>
    <div className="container">
      <h1>Page not found!</h1>
      <p>
        <Link to={`/`}>Back to homepage</Link>
      </p>
    </div>
  </Page>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    about: About,
    bag: Bag,
    conversations: Conversations,
    index: Index,
    privacy_policy: PrivacyPolicy,
    collaboration: Collaboration,
    conversation: Conversation,
    object: Product,
    shop: Shop,
    splash: Splash,
  },
});
